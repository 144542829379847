import React from 'react';
import ServiceForm from './ServiceForm';

const protocolOptions = ['http', 'https', 'icmp', 'custom'];

const PollingServiceForm = ({ service, handleFieldChange, isEditing, nodes, handleNodeSelectionChange }) => {
    return (
        <div>
            <ServiceForm
                service={service}
                handleFieldChange={handleFieldChange}
                isEditing={isEditing}
                nodes={nodes}
                handleNodeSelectionChange={handleNodeSelectionChange}
            />
            <label>Polling Hostname:</label>
            <input
                type="text"
                value={service.pollingEndpoint}
                onChange={(e) => handleFieldChange('pollingEndpoint', e.target.value)}
                placeholder="e.g., cloud.rajrai.net"
                disabled={!isEditing}
            />
            <br />
            <label>Protocol:</label>
            <select
                value={service.protocol}
                onChange={(e) => handleFieldChange('protocol', e.target.value)}
                disabled={!isEditing}
            >
                {protocolOptions.map((option) => (
                    <option key={option} value={option}>
                        {option.toUpperCase()}
                    </option>
                ))}
            </select>
            {service.protocol === 'custom' && isEditing && (
                <input
                    type="number"
                    placeholder="Enter custom port"
                    value={service.port || ''}
                    onChange={(e) => handleFieldChange('port', e.target.value)}
                    disabled={!isEditing}
                />
            )}
            <br />
            <label>Polling Interval (ms):</label>
            <input
                type="number"
                value={service.pollingInterval || ''}
                onChange={(e) => handleFieldChange('pollingInterval', e.target.value)}
                placeholder="e.g., 300000"
                disabled={!isEditing}
            />
            <br />
            {isEditing && (
                <>
                    <label>Auth Type:</label>
                    <select
                        onChange={(e) => {
                            handleFieldChange('auth', {
                                ...service.auth,
                                type: e.target.value
                            });
                        }}
                        value={service?.auth?.type || 'none'}
                    >
                        <option value="none">None</option>
                        <option value="basic">Basic</option>
                    </select>
                    {service.auth && service.auth.type === 'basic' && (
                        <div>
                            <label>Username:</label>
                            <input
                                type="text"
                                value={service.auth.username || ''}
                                onChange={(e) => {
                                    handleFieldChange('auth', {
                                        ...service.auth,
                                        username: e.target.value
                                    });
                                }}
                            />
                            <br />
                            <label>Password:</label>
                            <input
                                type="password"
                                value={service.auth.password || ''}
                                onChange={(e) => {
                                    handleFieldChange('auth', {
                                        ...service.auth,
                                        password: e.target.value
                                    });
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default PollingServiceForm;
