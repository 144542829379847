import React from 'react';

const metricTypes = ['uptime', 'rate', 'quantity'];

const ServiceForm = ({ service, handleFieldChange, isEditing, nodes, handleNodeSelectionChange }) => {
    return (
        <div>
            {isEditing ? (
                <>
                    <label>Service Name: </label>
                    <input
                        type="text"
                        value={service.name}
                        onChange={(e) => handleFieldChange('name', e.target.value)}
                    />
                </>
            ) : (
                <strong>{service.name}</strong>
            )}
            <br />
            <label>Metric Type:</label>
            <select
                value={service.metricType}
                onChange={(e) => handleFieldChange('metricType', e.target.value)}
                disabled={!isEditing}
            >
                {metricTypes.map((type) => (
                    <option key={type} value={type}>
                        {type.toUpperCase()}
                    </option>
                ))}
            </select>
            <br />

            <label>Nodes:</label>
            {nodes.map(node => (
                <div key={node.id}>
                    <input
                        type="checkbox"
                        checked={(service.nodeIds || []).includes(node.id)}
                        onChange={(e) => handleNodeSelectionChange(node.id, e.target.checked)}
                        disabled={!isEditing}
                    />
                    <label>{`${node.name} (${node.url})`}</label>
                </div>
            ))}
        </div>
    );
};

export default ServiceForm;
