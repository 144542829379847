export const formatUptime = (uptime) => {
    const uptimeStr = uptime.toFixed(10); // Convert to string with sufficient decimal places
    const uptimeParts = uptimeStr.split('.'); // Split into the whole number and the decimal part

    if (uptimeParts.length < 2) {
        return `${uptime.toFixed(1)}%`; // If there's no decimal part, just return with 1 decimal place
    }

    const decimalPart = uptimeParts[1];
    let nineCount = 0;

    // Count how many consecutive '9's are in the decimal part
    for (let i = 0; i < decimalPart.length; i++) {
        if (decimalPart[i] === '9') {
            nineCount++;
        } else {
            break;
        }
    }

    // Calculate the number of decimal places to display (number of 9's + 2)
    const decimalPlaces = nineCount + 2;

    return `${uptime.toFixed(decimalPlaces)}%`; // Return the formatted uptime with the calculated decimal places
};
