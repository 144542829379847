import React, { useState, useEffect } from 'react';
import { getConfig, addOrUpdateService, deleteService, getNodes, addOrUpdateNode, deleteNode } from '../services/api';
import PollingServiceForm from './PollingServiceForm';
import ReportingServiceForm from './ReportingServiceForm';
import { v4 as uuidv4 } from 'uuid';

const Configuration = ({ authKey, setAuthKey }) => {
    const [config, setConfig] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [error, setError] = useState('');
    const [saveMessage, setSaveMessage] = useState('');
    const [isEditing, setIsEditing] = useState({});
    const [newPollingService, setNewPollingService] = useState(null);
    const [newReportingService, setNewReportingService] = useState(null);
    const [newNode, setNewNode] = useState({ url: '', name: '' });
    const [authStatus, setAuthStatus] = useState({});
    const [authKeys, setAuthKeys] = useState({});

    useEffect(() => {
        fetchNodes();
    }, []);

    const handleAuthKeyChange = (e) => {
        setAuthKey(e.target.value);
    };

    const handleNodeAuthKeyChange = (e, nodeId) => {
        authKeys[nodeId] = e.target.value;
        setAuthKeys({...authKeys});
    };

    const fetchConfig = async () => {
        try {
            const response = await getConfig(authKey);
            setConfig(response.data);
            await fetchNodes();
            setError('');
        } catch (error) {
            setError('Invalid auth key or error fetching config');
            setConfig([]);
        }
    };

    const fetchNodes = async () => {
        try {
            const response = await getNodes(authKey);
            setNodes(response.data);
            response.data.forEach(handleNodeAuthCheck);
        } catch (error) {
            setError('Error fetching nodes');
        }
    };

    const handleFieldChange = (originalIndex, field, value) => {
        const updatedConfig = [...config];
        updatedConfig[originalIndex][field] = value;
        setConfig(updatedConfig);
    };

    const handleNodeSelectionChange = (originalIndex, nodeId, isSelected) => {
        const updatedConfig = [...config];
        const service = updatedConfig[originalIndex];

        if (isSelected) {
            service.nodeIds = [...(service.nodeIds || []), nodeId];
        } else {
            service.nodeIds = (service.nodeIds || []).filter(id => id !== nodeId);
        }

        setConfig(updatedConfig);
    };

    const toggleEdit = (originalIndex) => {
        setIsEditing((prevState) => ({
            ...prevState,
            [originalIndex]: !prevState[originalIndex]
        }));
    };

    const handleDelete = async (originalIndex) => {
        const service = config[originalIndex];
        for (const node of nodes) {
            await deleteService(service.id, nodeAuthKey(node), node.url).catch(err => {});
        }
        await fetchConfig();
    };

    const handleDeleteNode = async (node) => {
        try {
            // First deactivate all services for the node
            for (const service of config) {
                const payload = createServicePayload(service, node.id);
                payload.active = false;  // Deactivate all services on the node
                await addOrUpdateService(payload, nodeAuthKey(node), node.url);
            }

            // After deactivating, delete the node
            await deleteNode(node.id, nodeAuthKey(node));
            await fetchNodes();  // Refresh the nodes after deleting
        } catch (error) {
            setError('Error deactivating services or deleting node');
        }
    };

    const createServicePayload = (service, nodeId) => ({
        name: service.name,
        id: service.id,
        metricType: service.metricType,
        nodeIds: service.nodeIds,
        active: (service.nodeIds || []).includes(nodeId)
    });

    const createPollingPayload = (service, nodeId) => ({
        ...createServicePayload(service, nodeId),
        strategy: 'polling',
        pollingEndpoint: service.pollingEndpoint,
        pollingInterval: service.pollingInterval,
        protocol: service.protocol,
        port: service.port,
        auth: service.auth
    });

    const createReportingPayload = (service, nodeId) => ({
        ...createServicePayload(service, nodeId),
        strategy: 'reporting',
        reportingAuthKey: service.reportingAuthKey
    });

    const saveConfig = async () => {
        try {
            for (const node of nodes) {
                for (const service of getPolling()) {
                    const payload = createPollingPayload(service, node.id);
                    await addOrUpdateService(payload, nodeAuthKey(node), node.url).catch(err => {
                        alert(err.response.data.message);
                        throw err;
                    });
                }
                for (const service of getReporting()) {
                    const payload = createReportingPayload(service, node.id);
                    await addOrUpdateService(payload, node.authKey || authKey, node.url).catch(err => {
                        alert(err.response.data.message);
                        throw err;
                    });
                }
            }
            setSaveMessage('Configuration saved successfully!');
        } catch (error) {
            setSaveMessage('Error saving configuration.');
        }
        await fetchConfig();
    };

    const handleAddPollingService = () => {
        const newService = {
            id: uuidv4(),  // Generate a unique ID for the new service
            name: '',
            metricType: 'uptime',
            pollingEndpoint: '',
            pollingInterval: 300000,
            protocol: 'http',
            port: null,
            auth: null,
            nodeIds: []
        };
        setNewPollingService(newService);
    };

    const handleAddReportingService = () => {
        const newService = {
            id: uuidv4(),  // Generate a unique ID for the new service
            name: '',
            metricType: 'uptime',
            reportingAuthKey: '',
            nodeIds: []
        };
        setNewReportingService(newService);
    };

    const saveNewPollingService = () => {
        setConfig([
            ...config,
            { ...newPollingService, strategy: 'polling' }
        ]);
        setNewPollingService(null);
    };

    const saveNewReportingService = () => {
        setConfig([
            ...config,
            { ...newReportingService, strategy: 'reporting' }
        ]);
        setNewReportingService(null);
    };

    const handleNodeChange = (e, nodeId = null) => {
        const { name, value } = e.target;

        if (nodeId === null) {
            setNewNode(prev => ({ ...prev, [name]: value }));
        } else {
            setNodes(prevNodes =>
                prevNodes.map(node =>
                    node.id === nodeId
                        ? { ...node, [name]: value }
                        : node
                )
            );
        }
    };

    const saveNewNode = async () => {
        try {
            await addOrUpdateNode(newNode, authKey);
            setNewNode({ url: '', name: '' });
            await fetchNodes();
        } catch (error) {
            setError('Error adding new node');
        }
    };

    const nodeAuthKey = node => {
        return !authKeys[node.id] || authKeys[node.id] === "" ? authKey : authKeys[node.id];
    };

    const handleEditNode = async (node) => {
        try {
            await addOrUpdateNode(node, nodeAuthKey(node))
                .then(() => handleNodeAuthCheck(node))
                .catch(() => {
                    alert(`Node ${node.name} not authorized!`);
                    setAuthStatus(prev => ({
                        ...prev,
                        [node.id]: 'not-authenticated'
                    }));
                });
            await fetchNodes();
        } catch (error) {
            setError('Error updating node');
        }
    };

    const handleNodeAuthCheck = async (node) => {
        try {
            const response = await getConfig(nodeAuthKey(node));
            if (response.status === 200) {
                setAuthStatus(prev => ({
                    ...prev,
                    [node.id]: 'authenticated'
                }));
            } else {
                setAuthStatus(prev => ({
                    ...prev,
                    [node.id]: 'not-authenticated'
                }));
            }
        } catch (error) {
            setAuthStatus(prev => ({
                ...prev,
                [node.id]: 'not-authenticated'
            }));
        }
    };

    const getPolling = () => config.filter(service => service.strategy === 'polling');

    const getReporting = () => config.filter(service => service.strategy === 'reporting');

    return (
        <div>
            <h2>Configuration</h2>
            <div>
                <label>
                    Auth Key:
                    <input type="password" value={authKey} onChange={handleAuthKeyChange} />
                </label>
                <button onClick={fetchConfig}>Fetch Configuration</button>
                <button onClick={saveConfig}>Save Configuration</button>
                {saveMessage && <p>{saveMessage}</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>

            {config.length > 0 && (
                <div>
                    {/* Polling Services */}
                    {getPolling().length > 0 && (
                        <>
                            <h3>Polling Services</h3>
                            {getPolling().map((service, index) => (
                                <div key={service.id || index} style={{ marginBottom: '20px' }}>
                                    <PollingServiceForm
                                        service={service}
                                        handleFieldChange={(field, value) => handleFieldChange(config.indexOf(service), field, value)}
                                        handleNodeSelectionChange={(nodeId, isSelected) => handleNodeSelectionChange(config.indexOf(service), nodeId, isSelected)}
                                        nodes={nodes}
                                        isEditing={isEditing[config.indexOf(service)]}
                                    />
                                    <button onClick={() => toggleEdit(config.indexOf(service))}>
                                        {isEditing[config.indexOf(service)] ? 'Save' : 'Edit'}
                                    </button>
                                    <button onClick={() => handleDelete(config.indexOf(service))}>Delete</button>
                                </div>
                            ))}
                        </>
                    )}

                    {/* Reporting Services */}
                    {getReporting().length > 0 && (
                        <>
                            <h3>Reporting Services</h3>
                            {getReporting().map((service, index) => (
                                <div key={service.id || index} style={{ marginBottom: '20px' }}>
                                    <ReportingServiceForm
                                        service={service}
                                        handleFieldChange={(field, value) => handleFieldChange(config.indexOf(service), field, value)}
                                        handleNodeSelectionChange={(nodeId, isSelected) => handleNodeSelectionChange(config.indexOf(service), nodeId, isSelected)}
                                        nodes={nodes}
                                        isEditing={isEditing[config.indexOf(service)]}
                                    />
                                    <button onClick={() => toggleEdit(config.indexOf(service))}>
                                        {isEditing[config.indexOf(service)] ? 'Save' : 'Edit'}
                                    </button>
                                    <button onClick={() => handleDelete(config.indexOf(service))}>Delete</button>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}

            <button onClick={handleAddPollingService}>Add Polling Service</button>
            <button onClick={handleAddReportingService}>Add Reporting Service</button>

            {/* New Polling Service Form */}
            {newPollingService && (
                <div>
                    <h4>New Polling Service</h4>
                    <PollingServiceForm
                        service={newPollingService}
                        handleFieldChange={(field, value) => setNewPollingService({ ...newPollingService, [field]: value })}
                        handleNodeSelectionChange={(nodeId, isSelected) => setNewPollingService({ ...newPollingService, nodeIds: isSelected ? [...newPollingService.nodeIds, nodeId] : newPollingService.nodeIds.filter(id => id !== nodeId) })}
                        nodes={nodes}
                        isEditing={true}
                    />
                    <button onClick={saveNewPollingService}>Save Polling Service</button>
                </div>
            )}

            {/* New Reporting Service Form */}
            {newReportingService && (
                <div>
                    <h4>New Reporting Service</h4>
                    <ReportingServiceForm
                        service={newReportingService}
                        handleFieldChange={(field, value) => setNewReportingService({ ...newReportingService, [field]: value })}
                        handleNodeSelectionChange={(nodeId, isSelected) => setNewReportingService({ ...newReportingService, nodeIds: isSelected ? [...newReportingService.nodeIds, nodeId] : newReportingService.nodeIds.filter(id => id !== nodeId) })}
                        nodes={nodes}
                        isEditing={true}
                    />
                    <button onClick={saveNewReportingService}>Save Reporting Service</button>
                </div>
            )}

            <div>
                <h3>Monitoring Hosts</h3>
                {nodes.map((node, index) => (
                    <div key={node.id}>
                        {!node.isEditing && <>
                            <span>{`${node.name} (${node.url}) - `}</span>
                            <span style={{ color: authStatus[node.id] === 'authenticated' ? 'green' : 'red' }}>
                                {authStatus[node.id] === 'authenticated' ? 'Authenticated' : 'Not Authenticated'}
                            </span>
                            <button onClick={() => setNodes(nodes.map((n, i) => i === index ? {
                                ...n,
                                isEditing: !n.isEditing
                            } : n))}>Edit</button>
                            {authStatus[node.id] === 'authenticated' && (
                                <button onClick={() => handleDeleteNode(node)}>Delete</button>
                            )}
                        </>}
                        {node.isEditing && (
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    value={node.name}
                                    onChange={e => handleNodeChange(e, node.id)}
                                />
                                <input
                                    type="text"
                                    name="url"
                                    value={node.url}
                                    onChange={e => handleNodeChange(e, node.id)}
                                />
                                <input
                                    type="password"
                                    name="authKey"
                                    value={authKeys[node.id]}
                                    onChange={e => handleNodeAuthKeyChange(e, node.id)}
                                />
                                <button onClick={() => handleEditNode(node)}>Save</button>
                            </div>
                        )}
                    </div>
                ))}
                <input
                    type="text"
                    name="name"
                    placeholder="Node Name"
                    value={newNode.name}
                    onChange={handleNodeChange}
                />
                <input
                    type="text"
                    name="url"
                    placeholder="Node URL"
                    value={newNode.url}
                    onChange={handleNodeChange}
                />
                <button onClick={saveNewNode}>Add Node</button>
            </div>
        </div>
    );
};

export default Configuration;
