import axios from 'axios';

// Get the base URL from the environment variable or fallback to the default
const API_BASE_URL = process.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL;  // Backend URL

// Function to get the config with auth key
export const getConfig = async (authKey, url) => {
    return axios.get(`${url || API_BASE_URL}/config`, {
        headers: { 'auth-key': authKey }
    });
};

// Function to add or update a service
export const addOrUpdateService = async (serviceData, authKey, url) => {
    return axios.post(`${url || API_BASE_URL}/config/service`, serviceData, {
        headers: { 'auth-key': authKey }
    });
};

// Function to delete a service
export const deleteService = async (serviceId, authKey, url) => {
    return axios.delete(`${url || API_BASE_URL}/config/service/${serviceId}`, {
        headers: { 'auth-key': authKey }
    });
};

// Function to get the service IDs from /health/all
export const getServiceIds = async (url) => {
    return (await axios.get(`${url || API_BASE_URL}/health/all`)).data.serviceIds;
};

// Updated function to get service metrics by a list of service IDs and intervals
export const getServiceMetrics = async (serviceIds, intervals, url) => {
    return axios.post(`${url || API_BASE_URL}/health/services`, { serviceIds, intervals });
};

// Function to get nodes
export const getNodes = async () => {
    return axios.get(`${API_BASE_URL}/health/nodes`);
};

// Function to add or update a node
export const addOrUpdateNode = async (nodeData, authKey) => {
    return axios.post(`${API_BASE_URL}/config/node`, nodeData, {
        headers: { 'auth-key': authKey }
    });
};

// Function to delete a node
export const deleteNode = async (nodeId, authKey) => {
    return axios.delete(`${API_BASE_URL}/config/node/${nodeId}`, {
        headers: { 'auth-key': authKey }
    });
};
