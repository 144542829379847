import React, { useState } from 'react';
import Dashboard from './components/Dashboard';
import Configuration from './components/Configuration';

const App = () => {
  const [currentPage, setCurrentPage] = useState('dashboard');  // Track which page is active
  const [authKey, setAuthKey] = useState('');

  const handleNavigation = (page) => {
    setCurrentPage(page);
  };

  return (
      <div>
        <nav>
          <button onClick={() => handleNavigation('dashboard')}>Dashboard</button>
          <button onClick={() => handleNavigation('configuration')}>Configuration</button>
        </nav>

        <div>
          {currentPage === 'dashboard' && <Dashboard />}
          {currentPage === 'configuration' && <Configuration authKey={authKey} setAuthKey={setAuthKey} />}
        </div>
      </div>
  );
};

export default App;
