import React from 'react';
import ServiceForm from './ServiceForm';

const ReportingServiceForm = ({ service, handleFieldChange, isEditing, nodes, handleNodeSelectionChange }) => {
    return (
        <div>
            <ServiceForm
                service={service}
                handleFieldChange={handleFieldChange}
                isEditing={isEditing}
                nodes={nodes}
                handleNodeSelectionChange={handleNodeSelectionChange}
            />
            <label>Reporting Auth Key:</label>
            <input
                type="text"
                value={service.reportingAuthKey}
                onChange={(e) => handleFieldChange('reportingAuthKey', e.target.value)}
                disabled={!isEditing}
            />
            <br />
        </div>
    );
};

export default ReportingServiceForm;
