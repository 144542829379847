import React, { useEffect, useState } from 'react';
import { getNodes } from '../services/api';
import NodeDashboard from './NodeDashboard'; // Import the subcomponent

const Dashboard = () => {
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        const fetchNodes = async () => {
            try {
                const response = await getNodes();
                setNodes(response.data);
            } catch (error) {
                console.error('Error fetching nodes:', error);
            }
        };
        fetchNodes();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Dashboard</h1>
            {nodes.length > 0 ? (
                nodes.map(node => (
                    <NodeDashboard key={node.id} node={node} /> // Pass each node to the subcomponent
                ))
            ) : (
                <p>No nodes available</p>
            )}
        </div>
    );
};

export default Dashboard;
