import React, {useEffect, useState} from 'react';
import {getServiceIds, getServiceMetrics} from '../services/api';
import {Line} from 'react-chartjs-2';
import {useInView} from 'react-intersection-observer';
import {formatUptime} from "../util";
import LinkifyIt from 'linkify-it';

const NodeDashboard = ({node}) => {
    const [metrics, setMetrics] = useState([]);
    const [expanded, setExpanded] = useState({});  // Track which rows are expanded
    const [detailedMetrics, setDetailedMetrics] = useState({});  // Store detailed metrics for each service
    const [loading, setLoading] = useState(false);  // Track loading state
    const { ref, inView } = useInView();

    const [simpleIntervals] = useState(['1 year', '1 month', '1 week', '1 day', '1 hour', '15 minutes']);  // Intervals for the simple view
    const fullIntervals = [...simpleIntervals];  // Additional intervals for the full view

    useEffect(() => {
        const fetchServiceIdsAndMetrics = async () => {
            setLoading(true); // Start loading
            try {
                const serviceIdResponse = await getServiceIds(node.url);

                // Fetch simple metrics for all services
                const response = await getServiceMetrics(serviceIdResponse, simpleIntervals, node.url);
                setMetrics(response.data);

                // Fetch detailed metrics for expanded services
                const expandedServiceIds = Object.keys(expanded).filter(id => expanded[id]).map(id => Number(id));
                if (expandedServiceIds.length > 0) {
                    const detailedResponse = await getServiceMetrics(expandedServiceIds, fullIntervals, node.url);
                    setDetailedMetrics(detailedResponse.data.reduce((acc, metric) => {
                        acc[metric.id] = metric;
                        return acc;
                    }, {}));
                }

            } catch (error) {
                console.error('Error fetching service IDs and metrics:', error);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchServiceIdsAndMetrics();
        const intervalId = setInterval(() => {
            if (inView) {
                fetchServiceIdsAndMetrics();
            }
        }, 5000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [inView, simpleIntervals, expanded]);

    const toggleExpand = async (serviceId) => {
        setExpanded(prevState => ({
            ...prevState,
            [serviceId]: !prevState[serviceId]
        }));

        if (!expanded[serviceId] && !detailedMetrics[serviceId]) {
            try {
                const response = await getServiceMetrics([serviceId], fullIntervals, node.url);
                setDetailedMetrics(prevState => ({
                    ...prevState,
                    [serviceId]: response.data[0]  // Store the detailed metrics for this service
                }));
            } catch (error) {
                console.error(`Error fetching detailed metrics for service ${serviceId}:`, error);
            }
        }
    };

    const getServiceStatusColor = (metric) => {
        const lastHealthy = metric.lastHealthy ? new Date(metric.lastHealthy).getTime() : null;
        const pollingInterval = metric.pollingInterval || 0;
        const now = Date.now();

        if (metric.healthy) {
            return 'lightgreen';
        }

        if (lastHealthy) {
            const timeDiff = now - lastHealthy;
            const intervalDouble = pollingInterval * 2;
            const intervalQuadruple = pollingInterval * 4;

            if (timeDiff <= intervalDouble) return 'lightgreen';
            if (timeDiff > intervalDouble && timeDiff <= intervalQuadruple) return 'yellow';
            return 'lightcoral';
        }

        return 'lightgray';
    };

    const renderIntervals = (intervalMetrics) => {
        const sortedIntervals = intervalMetrics.sort((a, b) => {
            const timeUnits = { 'minute': 1, 'minutes': 1, 'hour': 60, 'hours': 60, 'day': 1440, 'days': 1440, 'week': 10080, 'weeks': 10080, 'month': 43200, 'months': 43200, 'year': 525600, 'years': 525600 };
            const aUnit = a.interval.split(' ')[1].toLowerCase();
            const bUnit = b.interval.split(' ')[1].toLowerCase();
            return timeUnits[aUnit] - timeUnits[bUnit];
        });

        const gridStyle = {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Updated to auto-fit
            gridGap: '20px',
            padding: '20px'
        };

        const itemStyle = {
            backgroundColor: '#f9f9f9',
            padding: '15px',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            width: '100%', // Ensure full width in the grid cell
            boxSizing: 'border-box' // Add this to prevent padding from affecting width
        };

        const titleStyle = {
            fontSize: '1.1em',
            marginBottom: '10px',
            display: 'block'
        };

        const metricStyle = {
            marginTop: '5px'
        };

        return (
            <div style={gridStyle}>
                {sortedIntervals.map((intervalData, index) => (
                    <div key={index} style={itemStyle}>
                        <strong style={titleStyle}>{intervalData.interval}</strong>
                        {intervalData.uptime && (
                            <div style={metricStyle}>Uptime: {intervalData.uptime.toFixed(2)}%</div>
                        )}
                        {intervalData.mean_latency && (
                            <div style={metricStyle}>Mean Latency: {intervalData.mean_latency.toFixed(2)} ms</div>
                        )}
                        {intervalData.aggregate_metrics && (
                            <div style={metricStyle}>Aggregated Metrics: {intervalData.aggregate_metrics}</div>
                        )}
                    </div>
                ))}
            </div>
        );
    };


    const renderUptimeSimple = (metric) => (
        <>
            <strong>Latest Latency: </strong>{metric.latest_latency ? `${metric.latest_latency} ms` : 'No data'}
            {metric.intervals && metric.intervals.length > 0 && renderIntervals(metric.intervals)}
        </>
    );

    const renderUptimeFull = (detailedMetric) => (
        <>
            {renderUptimeSimple(detailedMetric)}
            <strong>Recent Changes:</strong>
            <ul>
                {detailedMetric.recent_changes ? (
                    detailedMetric.recent_changes.map((change, index) => (
                        <li key={index}>
                            {change.healthy ? 'Healthy' : 'Unhealthy'} at {new Date(change.timestamp).toLocaleString()}
                        </li>
                    ))
                ) : (
                    <li>No recent changes</li>
                )}
            </ul>
        </>
    );

    const renderRateFull = (detailedMetric) => {
        const data = {
            labels: detailedMetric.recent_metrics?.map((m) => new Date(m.timestamp).toLocaleTimeString()) || [],
            datasets: [
                {
                    label: 'Value over time',
                    data: detailedMetric.recent_metrics?.map((m) => m.value) || [],
                    fill: false,
                    borderColor: 'blue',
                }
            ]
        };

        return (
            <>
                <Line data={data} />
                <span>Reported at: {detailedMetric.timestamp ? new Date(detailedMetric.timestamp).toLocaleString() : 'N/A'}</span>
            </>
        );
    };

    const renderQuantityFull = (detailedMetric) => (
        <>
            <strong>Aggregated Metrics:</strong>
            {renderIntervals(detailedMetric.intervals)}
            <strong>Recent Reports:</strong>
            <ul>
                {detailedMetric.recent_metrics ? (
                    detailedMetric.recent_metrics.map((m, index) => (
                        <li key={index}>
                            Value: {m.value} reported at {new Date(m.timestamp).toLocaleString()}
                        </li>
                    ))
                ) : (
                    <li>No recent reports</li>
                )}
            </ul>
        </>
    );

    const linkify = new LinkifyIt();

    const renderServiceName = (name) => {
        // Find links in the string
        const matches = linkify.match(name);

        if (!matches) {
            // If no URLs, return the plain name
            return <span>{name}</span>;
        }

        // Iterate over matches and the parts of the text that aren't URLs
        let lastIndex = 0;
        const elements = [];

        matches.forEach((match, index) => {
            // Add the text before the URL
            if (match.index > lastIndex) {
                elements.push(
                    <span key={`text-${index}`}>
                    {name.substring(lastIndex, match.index)}
                </span>
                );
            }

            // Add the URL
            elements.push(
                <a key={`link-${index}`} href={match.url} target="_blank" rel="noopener noreferrer">
                    {match.url}
                </a>
            );

            // Update last index
            lastIndex = match.lastIndex;
        });

        // Add any remaining text after the last URL
        if (lastIndex < name.length) {
            elements.push(
                <span key="text-end">
                {name.substring(lastIndex)}
            </span>
            );
        }

        return elements;
    };

    const renderMetric = (metric) => {
        const isExpanded = expanded[metric.id];
        const backgroundColor = getServiceStatusColor(metric);
        const detailedMetric = detailedMetrics[metric.id];

        const cardStyle = {
            backgroundColor: backgroundColor,
            margin: '10px 0',
            padding: '10px',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        };

        const gridStyle = {
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr', // Three columns: flexible name, centered last checked, flexible button
            alignItems: 'center',
            justifyContent: 'space-between', // Ensures proper alignment
        };

        const lastCheckedStyle = {
            justifySelf: 'center', // Center the "last checked" text
            textAlign: 'center',
        };

        const buttonStyle = {
            justifySelf: 'end', // Align the button to the right
        };

        return (
            <div key={metric.id} style={cardStyle} onClick={() => toggleExpand(metric.id)}>
                <div style={gridStyle}>
                    {/* Render service name with hyperlink detection */}
                    <strong>{renderServiceName(metric.name)}</strong>
                    <span style={lastCheckedStyle}>
                    {metric.lastChecked ? `Last Checked: ${new Date(metric.lastChecked).toLocaleString()}` : 'N/A'}
                </span>
                    <button style={buttonStyle}>{isExpanded ? 'Collapse' : 'Expand'}</button>
                </div>
                {!isExpanded ? (
                    <div style={{ marginTop: '10px' }}>
                        {metric.metricType === 'uptime' && renderUptimeSimple(metric)}
                        {metric.metricType === 'rate' && <p>Rate (Simple)</p>}
                        {metric.metricType === 'quantity' && <p>Quantity (Simple)</p>}
                    </div>
                ) : (
                    <div style={{ marginTop: '10px' }}>
                        {detailedMetric && metric.metricType === 'uptime' && renderUptimeFull(detailedMetric)}
                        {detailedMetric && metric.metricType === 'rate' && renderRateFull(detailedMetric)}
                        {detailedMetric && metric.metricType === 'quantity' && renderQuantityFull(detailedMetric)}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div ref={ref}>
            {loading && (
                <div style={{
                    position: 'fixed',
                    top: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    zIndex: 1000,
                }}>
                    Updating...
                </div>
            )}
            <h2>Monitor: {node.name}</h2>
            {metrics.length === 0 ? (
                <p>No metrics available</p>
            ) : (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '10px' }}>
                    {metrics.map((metric) => renderMetric(metric))}
                </div>
            )}
        </div>
    );
};

export default NodeDashboard;
